<template>
  <svg
    class="group"
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.355469 57C0.355469 25.5198 25.8752 0 57.3555 0H218.355C249.836 0 275.355 25.5198 275.355 57V218C275.355 249.48 249.836 275 218.355 275H57.3555C25.8752 275 0.355469 249.48 0.355469 218V57Z"
      fill="currentColor"
      :class="props.backgroundClass"
    />
    <g filter="url(#filter0_i_7146_20158)">
      <path
        d="M111.073 74.4399C90.6362 74.4399 74.0073 91.0786 74.0073 111.521V164.48C74.0073 184.92 90.646 201.551 111.088 201.551H164.048C184.487 201.551 201.118 184.912 201.118 164.47V111.506C201.118 91.0688 184.48 74.4399 164.038 74.4399H111.073ZM175.696 94.7777C178.503 94.7777 180.781 97.0556 180.781 99.8622C180.781 102.669 178.503 104.947 175.696 104.947C172.89 104.947 170.612 102.669 170.612 99.8622C170.612 97.0556 172.89 94.7777 175.696 94.7777ZM137.563 104.947C155.791 104.947 170.612 119.768 170.612 137.996C170.612 156.223 155.788 171.044 137.563 171.044C119.335 171.044 104.514 156.221 104.514 137.996C104.514 119.77 119.335 104.947 137.563 104.947ZM137.563 115.116C124.925 115.116 114.683 125.358 114.683 137.996C114.683 150.633 124.925 160.876 137.563 160.876C150.2 160.876 160.443 150.633 160.443 137.996C160.443 125.358 150.2 115.116 137.563 115.116Z"
        fill="currentColor"
        :class="props.iconClass"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_7146_20158"
        x="56.2114"
        y="56.6445"
        width="162.702"
        height="166.702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20158"
        />
      </filter>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-[#101010]",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-primary group-hover:text-white",
  },
});
</script>
