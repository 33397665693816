<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="48"
    viewBox="0 0 100 48"
    fill="none"
  >
    <path
      d="M15.9765 20.9291L15.6807 21.2251H13.6096L13.0179 20.9291L6.50895 12.9351H6.21309L4.14206 15.0076V20.9291L3.8462 21.2251H0.295861L0 20.9291V0.796073L0.295861 0.5H3.8462L4.14206 0.796073V9.3822H4.43792L13.0179 0.796073L13.6096 0.5H15.0889L15.3848 0.796073V3.75681L9.46757 9.67827V9.97435L15.9765 17.9683V20.9291Z"
      fill="white"
    />
    <path
      d="M30.7512 13.8229V15.0072L30.4553 15.3033H21.2836L20.9878 15.5993C20.9878 16.0731 21.2245 16.5863 21.6978 17.1389C22.1712 17.6916 22.9207 17.9679 23.9464 17.9679C25.0312 17.9679 25.7216 17.6719 26.0174 17.0797L26.3133 16.7836H29.8636L30.1595 17.0797C30.0806 17.5732 29.9129 18.0568 29.6565 18.5305C29.4198 19.0042 29.0746 19.4779 28.621 19.9516C28.1673 20.4253 27.5362 20.8102 26.7275 21.1063C25.9188 21.3826 24.9918 21.5208 23.9464 21.5208C21.5598 21.5208 19.7254 20.7905 18.4434 19.3299C17.181 17.8692 16.5498 16.0336 16.5498 13.8229C16.5498 11.632 17.1613 9.80618 18.3842 8.34555C19.6268 6.86519 21.3823 6.125 23.6505 6.125C25.9188 6.125 27.6644 6.86519 28.8873 8.34555C30.1299 9.80618 30.7512 11.632 30.7512 13.8229ZM26.0174 12.3425L26.3133 12.0465C26.3133 11.928 26.2837 11.7603 26.2245 11.5431C26.1653 11.326 26.047 11.0694 25.8695 10.7733C25.7117 10.4575 25.4356 10.201 25.0411 10.0036C24.6663 9.78644 24.2028 9.67788 23.6505 9.67788C23.0982 9.67788 22.6249 9.78644 22.2304 10.0036C21.8556 10.201 21.5795 10.4575 21.402 10.7733C21.2442 11.0694 21.1357 11.326 21.0765 11.5431C21.0174 11.7603 20.9878 11.928 20.9878 12.0465L21.2836 12.3425H26.0174Z"
      fill="white"
    />
    <path
      d="M46.7287 13.8229V15.0072L46.4328 15.3033H37.2611L36.9652 15.5993C36.9652 16.0731 37.2019 16.5863 37.6753 17.1389C38.1487 17.6916 38.8982 17.9679 39.9239 17.9679C41.0087 17.9679 41.699 17.6719 41.9949 17.0797L42.2907 16.7836H45.8411L46.1369 17.0797C46.058 17.5732 45.8904 18.0568 45.634 18.5305C45.3973 19.0042 45.0521 19.4779 44.5985 19.9516C44.1448 20.4253 43.5136 20.8102 42.705 21.1063C41.8963 21.3826 40.9692 21.5208 39.9239 21.5208C37.5372 21.5208 35.7029 20.7905 34.4208 19.3299C33.1585 17.8692 32.5273 16.0336 32.5273 13.8229C32.5273 11.632 33.1388 9.80618 34.3617 8.34555C35.6043 6.86519 37.3597 6.125 39.628 6.125C41.8963 6.125 43.6418 6.86519 44.8647 8.34555C46.1074 9.80618 46.7287 11.632 46.7287 13.8229ZM41.9949 12.3425L42.2907 12.0465C42.2907 11.928 42.2612 11.7603 42.202 11.5431C42.1428 11.326 42.0245 11.0694 41.847 10.7733C41.6892 10.4575 41.413 10.201 41.0185 10.0036C40.6438 9.78644 40.1803 9.67788 39.628 9.67788C39.0757 9.67788 38.6023 9.78644 38.2079 10.0036C37.8331 10.201 37.557 10.4575 37.3794 10.7733C37.2217 11.0694 37.1132 11.326 37.054 11.5431C36.9948 11.7603 36.9652 11.928 36.9652 12.0465L37.2611 12.3425H41.9949Z"
      fill="white"
    />
    <path
      d="M69.5179 9.67868L65.9675 20.9295L65.6717 21.2255H61.8255L61.5296 20.9295L59.1627 12.0473H58.8669L56.5 20.9295L56.2041 21.2255H52.3579L52.0621 20.9295L48.5117 9.67868V6.71794L48.8076 6.42188H51.4703L51.7662 6.71794L54.1331 15.008H54.429L56.7958 6.71794L57.0917 6.42188H60.9379L61.2338 6.71794L63.6007 15.008H63.8965L66.2634 6.71794L66.5593 6.42188H69.222L69.5179 6.71794V9.67868Z"
      fill="white"
    />
    <path
      d="M84.3197 20.9287L84.0239 21.2247H80.7694L80.4735 20.9287L80.1777 20.3365H79.8818C79.8029 20.4155 79.6747 20.524 79.4972 20.6622C79.3197 20.8004 78.9449 20.978 78.3729 21.1951C77.8207 21.4122 77.2388 21.5208 76.6273 21.5208C74.9902 21.5208 73.6885 21.1063 72.722 20.2773C71.7752 19.4286 71.3018 18.264 71.3018 16.7836C71.3018 15.9349 71.4498 15.1947 71.7456 14.5631C72.0612 13.9315 72.4458 13.4479 72.8995 13.1123C73.3531 12.757 73.8857 12.4708 74.4971 12.2537C75.1283 12.0366 75.7003 11.8984 76.2131 11.8392C76.726 11.78 77.2585 11.7504 77.8108 11.7504C78.1658 11.7504 78.511 11.78 78.8463 11.8392C79.2013 11.8787 79.4676 11.928 79.6451 11.9872L79.8818 12.0465L80.1777 11.7504V11.4543C80.1777 10.7043 80.0495 10.2207 79.7931 10.0036C79.5564 9.78644 78.9942 9.67788 78.1067 9.67788C77.7122 9.67788 77.4065 9.68775 77.1895 9.70749C76.9922 9.72723 76.7753 9.80618 76.5386 9.94435C76.3019 10.0825 76.1342 10.2898 76.0356 10.5661L75.7398 10.8622H72.1894L71.8936 10.5661C71.8936 9.46076 72.3965 8.44424 73.4025 7.51655C74.4084 6.58885 75.9765 6.125 78.1067 6.125C80.3947 6.125 82.0022 6.67767 82.9292 7.78301C83.8562 8.88835 84.3197 10.6056 84.3197 12.9347V20.9287ZM80.1777 15.3033L79.8818 15.0072C79.4873 14.8098 78.8956 14.7111 78.1067 14.7111C76.3315 14.7111 75.4439 15.3033 75.4439 16.4876C75.4439 17.4745 76.1342 17.9679 77.5149 17.9679C78.2644 17.9679 78.8956 17.7705 79.4084 17.3758C79.9213 16.981 80.1777 16.4876 80.1777 15.8954V15.3033Z"
      fill="white"
    />
    <path
      d="M100 9.67868L95.2662 21.5216C94.9309 22.3506 94.6153 23.0217 94.3195 23.5349C94.0236 24.0481 93.6291 24.5317 93.136 24.9857C92.6429 25.4396 92.0413 25.7653 91.3313 25.9627C90.6212 26.1601 89.7632 26.2588 88.7573 26.2588H87.278L86.9821 25.9627V23.002L87.278 22.7059H88.7573C89.4673 22.7059 89.9506 22.6269 90.207 22.469C90.4634 22.3111 90.6705 21.9953 90.8283 21.5216V21.2255L86.0945 9.67868V6.71794L86.3904 6.42188H89.0531L89.349 6.71794L92.8993 16.7844H93.1952L96.7455 6.71794L97.0414 6.42188H99.7041L100 6.71794V9.67868Z"
      fill="white"
    />
    <path
      d="M41.4224 46.5349C39.5548 46.5349 38.0028 45.9165 36.7665 44.6798C35.5303 43.443 34.9121 41.6931 34.9121 39.4301C34.9121 37.1671 35.5303 35.4172 36.7665 34.1804C38.0028 32.9436 39.5548 32.3252 41.4224 32.3252C42.9874 32.3252 44.2106 32.72 45.0918 33.5094C45.973 34.2856 46.5253 35.2724 46.7489 36.4697L46.5516 36.6671H45.7625L45.5652 36.4697C45.0918 34.3646 43.7108 33.312 41.4224 33.312C39.7784 33.312 38.4763 33.8778 37.5162 35.0093C36.5693 36.1408 36.0958 37.6144 36.0958 39.4301C36.0958 41.2458 36.5693 42.7194 37.5162 43.8509C38.4763 44.9824 39.7784 45.5481 41.4224 45.5481C42.2772 45.5481 42.9874 45.4166 43.553 45.1534C44.1317 44.8903 44.5591 44.5153 44.8353 44.0285C45.1115 43.5285 45.3022 43.0154 45.4074 42.4891C45.5126 41.9628 45.5652 41.3379 45.5652 40.6142L45.368 40.4169H40.8305L40.6332 40.2195V39.6274L40.8305 39.4301H46.5516L46.7489 39.6274V46.1402L46.5516 46.3376H46.1571L45.9598 46.1402L45.7625 44.5614H45.5652C44.6841 45.8771 43.3031 46.5349 41.4224 46.5349Z"
      fill="white"
    />
    <path
      d="M54.6439 40.2194L54.2493 40.0221H50.8956L50.6983 40.2194V46.1401L50.501 46.3375H49.7119L49.5146 46.1401V32.7199L49.7119 32.5225H55.433C56.9192 32.5225 58.0108 32.8317 58.7079 33.4501C59.4181 34.0553 59.7732 34.996 59.7732 36.2723C59.7732 36.8775 59.6811 37.4104 59.497 37.8709C59.326 38.3314 59.109 38.6932 58.846 38.9563C58.5961 39.2195 58.2936 39.4366 57.9385 39.6076C57.5834 39.7786 57.2546 39.8905 56.9521 39.9431C56.6496 39.9957 56.3405 40.0221 56.0248 40.0221V40.2194L59.3786 45.548V46.1401L59.1813 46.3375H58.7868L58.3922 46.1401L54.6439 40.2194ZM57.8004 38.4235C58.3264 38.0156 58.5895 37.2985 58.5895 36.2723C58.5895 35.246 58.333 34.529 57.8201 34.1211C57.3203 33.7132 56.5904 33.5093 55.6303 33.5093H50.8956L50.6983 33.7066V38.8379L50.8956 39.0353H55.433C56.4852 39.0353 57.2743 38.8313 57.8004 38.4235Z"
      fill="white"
    />
    <path
      d="M70.0643 46.1205C69.3146 46.3968 68.5123 46.5349 67.6574 46.5349C66.8026 46.5349 66.0003 46.3968 65.2506 46.1205C64.501 45.831 63.8302 45.41 63.2384 44.8574C62.6597 44.2916 62.1994 43.5483 61.8574 42.6273C61.5154 41.6931 61.3445 40.6274 61.3445 39.4301C61.3445 38.2328 61.5154 37.1736 61.8574 36.2526C62.1994 35.3185 62.6597 34.5751 63.2384 34.0225C63.8302 33.4568 64.501 33.0357 65.2506 32.7594C66.0003 32.47 66.8026 32.3252 67.6574 32.3252C68.5123 32.3252 69.3146 32.47 70.0643 32.7594C70.8139 33.0357 71.4781 33.4568 72.0568 34.0225C72.6486 34.5751 73.1155 35.3185 73.4575 36.2526C73.7994 37.1736 73.9704 38.2328 73.9704 39.4301C73.9704 40.6274 73.7994 41.6931 73.4575 42.6273C73.1155 43.5483 72.6486 44.2916 72.0568 44.8574C71.4781 45.41 70.8139 45.831 70.0643 46.1205ZM71.3663 34.8711C70.4325 33.8317 69.1962 33.312 67.6574 33.312C66.1187 33.312 64.8758 33.8317 63.9288 34.8711C62.995 35.9106 62.5282 37.4302 62.5282 39.4301C62.5282 41.43 62.995 42.9496 63.9288 43.989C64.8758 45.0284 66.1187 45.5481 67.6574 45.5481C69.1962 45.5481 70.4325 45.0284 71.3663 43.989C72.3132 42.9496 72.7867 41.43 72.7867 39.4301C72.7867 37.4302 72.3132 35.9106 71.3663 34.8711Z"
      fill="white"
    />
    <path
      d="M86.5905 32.7199V41.4035C86.5905 42.9824 86.1302 44.2323 85.2096 45.1533C84.2889 46.0743 83.0395 46.5348 81.4613 46.5348C79.883 46.5348 78.6336 46.0743 77.7129 45.1533C76.7923 44.2323 76.332 42.9824 76.332 41.4035V32.7199L76.5293 32.5225H77.3184L77.5157 32.7199V41.4035C77.5157 42.6798 77.8708 43.6929 78.581 44.4428C79.3043 45.1796 80.2644 45.548 81.4613 45.548C82.7238 45.548 83.6971 45.1862 84.381 44.4626C85.0649 43.7258 85.4069 42.7061 85.4069 41.4035V32.7199L85.6041 32.5225H86.3933L86.5905 32.7199Z"
      fill="white"
    />
    <path
      d="M98.9347 33.4501C99.6449 34.0553 100 34.996 100 36.2723C100 37.5485 99.6449 38.4958 98.9347 39.1142C98.2377 39.7194 97.146 40.0221 95.6599 40.0221H91.1224L90.9251 40.2194V46.1401L90.7279 46.3375H89.9387L89.7415 46.1401V32.7199L89.9387 32.5225H95.6599C97.146 32.5225 98.2377 32.8317 98.9347 33.4501ZM98.0272 38.4235C98.5533 38.0156 98.8163 37.2985 98.8163 36.2723C98.8163 35.246 98.5599 34.529 98.047 34.1211C97.5472 33.7132 96.8172 33.5093 95.8571 33.5093H91.1224L90.9251 33.7066V38.8379L91.1224 39.0353H95.6599C96.712 39.0353 97.5011 38.8313 98.0272 38.4235Z"
      fill="white"
    />
  </svg>
</template>
