<template>
  <svg
    class="group"
    viewBox="0 0 275 275"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.177734 57C0.177734 25.5198 25.6975 0 57.1777 0H218.178C249.658 0 275.178 25.5198 275.178 57V218C275.178 249.48 249.658 275 218.178 275H57.1777C25.6975 275 0.177734 249.48 0.177734 218V57Z"
      fill="currentColor"
      :class="props.backgroundClass"
    />
    <g clip-path="url(#clip0_7146_20254)" filter="url(#filter0_i_7146_20254)">
      <path
        d="M142.179 76.0345C149.08 75.9277 155.941 75.991 162.794 75.9277C163.209 83.999 166.112 92.2206 172.02 97.927C177.916 103.776 186.257 106.453 194.371 107.359V128.591C186.767 128.342 179.126 126.76 172.226 123.485C169.22 122.125 166.42 120.373 163.68 118.582C163.644 133.989 163.743 149.376 163.581 164.72C163.17 172.091 160.738 179.427 156.451 185.501C149.554 195.613 137.584 202.205 125.289 202.411C117.748 202.842 110.214 200.785 103.788 196.997C93.1383 190.717 85.6444 179.221 84.553 166.883C84.4155 164.27 84.3944 161.652 84.4897 159.037C85.4388 149.004 90.4018 139.406 98.1053 132.877C106.837 125.273 119.068 121.65 130.521 123.794C130.628 131.604 130.315 139.406 130.315 147.217C125.083 145.524 118.97 145.999 114.398 149.174C111.053 151.378 108.526 154.619 107.205 158.4C106.113 161.074 106.426 164.043 106.489 166.883C107.743 175.535 116.063 182.808 124.945 182.021C130.833 181.958 136.476 178.541 139.545 173.538C140.538 171.786 141.649 169.995 141.708 167.935C142.226 158.503 142.021 149.111 142.084 139.679C142.128 118.424 142.021 97.227 142.183 76.0385L142.179 76.0345Z"
        fill="currentColor"
        :class="props.iconClass"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_7146_20254"
        x="76.1055"
        y="75.9277"
        width="126.546"
        height="130.546"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_7146_20254"
        />
      </filter>
      <clipPath id="clip0_7146_20254">
        <rect
          width="126.546"
          height="126.546"
          fill="white"
          transform="translate(76.1055 75.9277)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-[#101010]",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-primary group-hover:text-white",
  },
});
</script>
